import { template as template_22d2e64e50ec4cf78f3203e3cd495ab6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_22d2e64e50ec4cf78f3203e3cd495ab6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
